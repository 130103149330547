import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { getDistribuicao } from '../../../utils/crm/getDistribuicao';
// import { listCorretores, updateEscala } from '../../../controllers/crm/escalaController';
import { getDiaSemana } from '../../../utils/crm/getDiaSemana';
import { getOperEscala, setOperEscala } from '../../../controllers/crm/operescalaController';

export function CorretorIncluir(
  { setOpenCorretorIncluir,
    openCorretorIncluir,
    idconta,
    idusuario,
    setSnackbar,
    listEscalas,
    escala,
    // hrInicial,
    // setHrInicial,
    // hrFinal,
    // setHrFinal,
    nome,
  }) {



  const [load, setLoad] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [operador, setOperador] = useState()
  const [escalado, setEscalado] = useState(false)
  // const [corretores, setCorretores] = useState([])
  const [nomeOperador, setNomeOperador] = useState(nome)

  const [pHinicial, setPHinicial] = useState(dayjs(escala.hrinicial))
  const [pHfinal, setPHfinal] = useState(dayjs(escala.hrfinal))

  // console.log(escala)
  // console.log(pHinicial.toString())
  // console.log(pHfinal.toString())



  const handleNo = () => {
    setOpenCorretorIncluir(false);
  };

  const loadOperador = async () => {
    setDisabled(true)
    const dados = {
      idconta: idconta,
      idescala: escala.idescala,
      idoperador: idusuario
    }
    const api = await getOperEscala(dados)
    // console.log(api)
    if (api.status !== 200) {
      setOperador(null)
      setSnackbar({ severity: 'error', message: 'Falha ao carregar dados do operador!' });
    }
    else if (api.data) {
      setPHinicial(dayjs(api.data.hrinicial))
      setPHfinal(dayjs(api.data.hrfinal))
      setOperador(api.data)
    }
    setDisabled(false)
  }

  const handleGravar = async () => {
    try {
      setDisabled(true)
      setLoad(true)
      const d = new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
      const dados = {
        idoperador: idusuario,
        idescala: escala.idescala,
        idconta: idconta,
        diamesano: escala.diamesano,
        version: operador ? operador.version : 0,
        versionescala: escala.version,
        historico: escala.historico,
        // hrinicial: dayjs(escala.diamesano.format('YYYY-MM-DD') + ' ' + hrInicial.format('HH:mm')).toISOString(),
        // hrfinal: dayjs(escala.diamesano.format('YYYY-MM-DD') + ' ' + hrFinal.format('HH:mm')).toISOString(),
        hrinicial: pHinicial.toISOString(),
        hrfinal: pHfinal.toISOString(),
        participar: operador ? !operador.isactive : true,
        idusuario: idusuario,
        nome: nomeOperador,
      }
      // console.log(dados)
      const response = await setOperEscala(dados)
      if (response.status === 200) {
        escala.version = response.data.version
        setOperador(response.data.operescala[0])
        setSnackbar({ severity: 'success', message: 'Operação realizada com sucesso!' });
        await listEscalas()
      }
      else if (response.status === 201) {
        setSnackbar({ severity: 'error', message: 'Falha de acesso concorrente, recarregue os dados novamente.' });
        listEscalas()
      }
      else if (response.status === 202) {
        setSnackbar({ severity: 'error', message: 'Operador com CRM desativado. Ativar Operador em Minha Conta.' });
        listEscalas()
      }
      else {
        setSnackbar({ severity: 'error', message: 'Falha ao realizar a operação! Tente novamente mais tarde.' });
      }
    }
    catch (error) {
      setSnackbar({ severity: 'error', message: 'Serviço fora do ar, tente novamente mais tarde.' });
    }
    finally {
      setDisabled(false)
      setLoad(false)
    }
  }

  useEffect(() => {
    loadOperador()
  }, [])

  useEffect(() => {
    if (operador) {
      if (operador.isactive) {
        setEscalado(true)
      }
      else {
        setEscalado(false)
      }
    }
  }, [operador])


  return (
    <Dialog
      maxWidth="xs"
      open={openCorretorIncluir}
      onClose={handleNo}
    >
      {(disabled) && ((
        <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
          <CircularProgress />
        </div>
      ))}
      <DialogTitle>
        <Typography variant="h4" component="div" sx={{ display: 'flex', justifyContent: 'center' }}>
          {`${getDiaSemana(escala.diamesano).toLocaleUpperCase()} - ${new Date(escala.diamesano).toLocaleDateString()}`}
        </Typography>
        <Typography variant="h5" component="div" sx={{ display: 'flex', justifyContent: 'center' }}>
          {`Distribuição: ${getDistribuicao(escala.tpdistribuicao)} - ${dayjs(escala.hrinicial).format('HH:mm')} / ${dayjs(escala.hrfinal).format('HH:mm')}`}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container
          direction={'row'}
          minHeight={'120px'}
          maxWidth={'280px'}
        >
          <Grid container direction={'row'} >
            <Grid item mb={1} >
              <Typography variant="body1" fontSize={14} component="div" >
                Informe seu horário de disponibilidade para recebimento de leads:
              </Typography>
            </Grid>
            <Grid container direction={'row'} gap={1} justifyContent={'space-between'} >
              <Grid item >
                <TimePicker
                  label="Hr Inicial"
                  ampm={false}
                  value={pHinicial}
                  onChange={(date) => setPHinicial(date)}
                  views={['hours', 'minutes']}
                  format="HH:mm"
                  disabled={escalado || disabled}
                  sx={{ width: '100px' }}
                />
              </Grid>
              <Grid item >
                <TimePicker
                  label="Hr Final"
                  value={pHfinal}
                  ampm={false}
                  onChange={(date) => setPHfinal(date)}
                  views={['hours', 'minutes']}
                  format="HH:mm"
                  // disabled={escalado ? operador ? operador.isactive : false : false}
                  disabled={escalado || disabled}
                  sx={{ width: '100px' }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container direction={'row'}
            justifyContent={'flex-start'}
            border={1}
            borderColor={grey[200]}
            width={'310px'}
            mt={1}
          > */}
          {/* {corretores.length > 0 && (
              <Grid container direction={'row'} gap={1} bgcolor={grey[200]} overflow={'auto'} maxHeight={'100px'}  >
                {corretores.map((corretor, i) => (
                  <Grid container direction={'row'} gap={1} key={i} >
                    <Grid item >
                      <Typography variant="body1" fontSize={12} px={1} >
                        {corretor.nome}
                      </Typography>
                    </Grid>
                    <Grid item px={1}>
                      <Typography variant="body1" fontSize={12} >
                        {dayjs(corretor.hrinicial).format('HH:mm')} / {dayjs(corretor.hrfinal).format('HH:mm')}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
                }
              </Grid>
            )}
            {corretores.length === 0 && (
              <Typography variant="body1" fontSize={12} >
                Não há corretores cadastrados para esta escala.
              </Typography>
            )} */}
          {/* </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'flex-end'} gap={2}>
          {escalado && (
            <Button variant="contained"
              color='error'
              disabled={disabled} size='small'
              onClick={() => handleGravar()}
            >
              Não Participar
            </Button>
          )}
          {!escalado && (
            <Button variant="contained"
              color='success'
              disabled={disabled} size='small'
              onClick={() => handleGravar()}
            >
              Participar
            </Button>
          )}
          <Button variant="contained"
            onClick={() => handleNo()}
            disabled={disabled} size='small'>
            Fechar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};