// Formulario de seleção de escalas para o CRM.
// O Formulário possui duas áreas de controle, uma para escalas e outra para operadores.
// A área de escalas possui uma lista de Cards com as escalas disponíveis e um botão para selecionar a escala.
// A área de operadores possui uma lista de Cards com os operadores disponíveis e um botão para selecionar o operador.
// O formulario usa react e @mui, bem como o controller getEscalas.


import React, { useEffect, useState } from 'react';
import {
  Button, Card, CardContent, CardActions, Typography, Grid,
  Box, Divider, List, ListItem, ListItemButton, ListItemText, IconButton, Alert, CircularProgress
} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Snackbar from '@mui/material/Snackbar';
import { grey } from '@mui/material/colors';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import { getEscala, updateEscala } from '../../../controllers/crm/escalaController';
import { getDiaSemana } from '../../../utils/crm/getDiaSemana';
import { getDistribuicao } from '../../../utils/crm/getDistribuicao';
import { escalaStatus } from '../../../constants/crm';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import EditCalendarTwoToneIcon from '@mui/icons-material/EditCalendarTwoTone';
import { HabilitarCorretores } from './HabilitarCorretores';
import { ExcluirEscala } from './ExcluirEscala';
import { AdicionarEscala } from './AdicionarEscala';
import { EditarEscala } from './EditEscala';
import { getEscalaSituacao } from '../../../utils/crm/getEscalaSituacao';
import { getEscalaCor } from '../../../utils/crm/getCorEscala';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br.js';
dayjs.locale("pt-br.js");

// Função Principal
export default function PlantoesSelection(props) {

  // Constantes Recebidas
  // const [account, setAccount] = useState(props.account)
  const idconta = props.account.conta
  const idusuario = props.account.id

  // Constantes de Estado
  const [operadores, setOperadores] = useState([])
  const [escalas, setEscalas] = useState([])
  const [filteredEscalas, setFilteredEscalas] = useState([])
  const [escala, setEscala] = useState({})
  const [open, setOpen] = useState(false)
  const [snack, setSnack] = useState(false)
  const [snackMessage, setSnackMessage] = useState('')
  const [loading, setLoading] = useState(true)
  const [disabled, setDisabled] = useState(false)
  const [newDay, setNewDay] = useState(dayjs(new Date()));
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openCorretores, setOpenCorretores] = useState(false);
  const [openDialogExcluir, setOpenDialogExcluir] = useState(false);
  const [openDialogEditar, setOpenDialogEditar] = useState(false);

  const hoje = dayjs(dayjs().format('YYYY-MM-DD')).toISOString()

  // // Constantes do Menu
  const navItems = ['Leads', 'Escalas', 'Operadores', 'Carteiras'];
  const [mobileOpen, setMobileOpen] = React.useState(false);

  // Constantes do Snackbar
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  // Funções Mobile
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // const drawer = (
  //   <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
  //     <Typography variant="h6" sx={{ my: 2 }}>
  //       GIMOV - OnLine
  //     </Typography>
  //     <Divider />
  //     <List>
  //       <ListItem key={'imoveis'} disablePadding>
  //         <ListItemButton sx={{ textAlign: 'left' }}>
  //           <ListItemText primary={'Imóveis'} />
  //         </ListItemButton>
  //       </ListItem>

  //       {navItems.map((item) => (
  //         <ListItem key={item} disablePadding>
  //           <ListItemButton sx={{ textAlign: 'left' }}>
  //             <ListItemText primary={item} />
  //           </ListItemButton>
  //         </ListItem>
  //       ))}
  //     </List>
  //   </Box>
  // );

  // const container = window !== undefined ? () => window().document.body : undefined;

  // Funções das Escalas

  // Executar Escala
  const handleExecutar = async (escala) => {
    try {
      setDisabled(true)
      setLoading(true)
      const historico = ' # ' + new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString() + ' - Controle colocado em Execução (M) ' + escala.historico
      const response = await updateEscala({ idescala: escala.idescala, status: escalaStatus.Executando, idusuario: idusuario, historico: historico, version: escala.version });
      // console.log('response', response)
      if (response.status !== 200) {
        if (response.status === 210) {
          setSnackbar({ severity: 'error', message: 'Falha de acesso concorrente, realize novamente a operação.' });
          listEscalas()
        }
        else {
          setSnackbar({ severity: 'error', message: 'Erro ao colocar o controle em execução!' });
          return;
        }

      }
      if (response.status === 200) {
        escala.historico = historico
        escala.version = response.data.version
        escala.status = escalaStatus.Executando
        setEscala(escala)
        setSnackbar({ severity: 'success', message: 'Controle colocado em execução com sucesso!' });
      }
    }
    catch (error) {
      setSnackbar({ severity: 'error', message: 'Acesso Concorrente!' });
      listEscalas()
    }
    finally {
      setDisabled(false)
      setLoading(false)
    }
  }

  const handleEditar = async (escala) => {
    setEscala(escala)
    setOpenDialogEditar(true)
  }

  // Encerrar Escala/Suspenso
  const handleSuspender = async (escala) => {
    try {
      setDisabled(true)
      setLoading(true)
      const historico = ' # ' + new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString() + ' - Controle Suspenso (M) ' + escala.historico
      const response = await updateEscala({ idescala: escala.idescala, status: escalaStatus.Suspensa, idusuario: idusuario, historico: historico, version: escala.version });

      if (response.status !== 200) {
        if (response.status === 210) {
          setSnackbar({ severity: 'error', message: 'Falha de acesso concorrente, realize novamente a operação.' });
          listEscalas()
        }
        else {
          setSnackbar({ severity: 'error', message: 'Erro ao colocar o controle em suspensão!' });
          return;
        }
      }
      if (response.status === 200) {
        escala.historico = historico
        escala.version = response.data.version
        escala.status = escalaStatus.Suspensa
        setSnackbar({ severity: 'success', message: 'Controle suspenso com sucesso!' });
      }
    }
    catch (e) {

      setSnackbar({ severity: 'error', message: 'Acesso Concorrente!' });
      listEscalas()
    }
    finally {
      setDisabled(false)
      setLoading(false)
    }
  }

  // Excluir escala
  const handleExcluir = async (escala) => {
    setEscala(escala)
    setOpenDialogExcluir(true)
  }

  // Definir Dia
  function handleDayEscala(day) {
    setNewDay(day)
  }

  // Listar Escalas
  async function listEscalas() {
    try {
      setLoading(true)
      const query = {
        take: 8,
        where: {
          idconta: idconta,
          isactive: true,
          diamesano: { gte: newDay.add(-1, 'day').toDate() }
        },
        orderBy: [
          { diamesano: 'asc' }
        ]
      };
      const response = await getEscala(query);
      if (response.status !== 200) {
        setSnackbar({ severity: 'error', message: 'Erro ao carregar controles!' });
        setLoading(false)
        return;
      }
      if (response.data) {
        if (response.data.length === 0) {
          setEscalas([]);
          setFilteredEscalas([]);
          setSnackbar({ severity: 'info', message: 'Nenhum controle cadastrado!' });
        }
        if (response.data.length > 0) {
          setEscalas(response.data);
          setFilteredEscalas(response.data);
        }
      }
      setLoading(false)
    }
    catch (error) {
      setLoading(false)
      setSnackbar({ severity: 'error', message: 'Serviço fora do ar, tente novamente mais tarde.' });
    }
  }

  // Abrir Corretores
  function handleCorretores(_escala) {
    setEscala(_escala)
    setOpenCorretores(true)
  }

  useEffect(() => {
    listEscalas()
  }, [newDay])

  return (
    <Grid container>
      {(loading) && ((
        <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
          <CircularProgress />
        </div>
      ))}
      <Grid
        container
        component={'div'}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        // mt={1}
        bgcolor={'#f5f5f5'}
      >
        <DatePicker
          label="Selecione o dia"
          format='DD/MM/YYYY'
          value={newDay}
          onChange={handleDayEscala}
          sx={{ width: '150px' }}
        />
        <Button
          variant="outlined"
          size='large'
          startIcon={<CalendarMonthTwoToneIcon />}
          onClick={() => { setOpenDialogAdd(true) }}
          sx={{ height: '60px' }}
        >
          Adicionar
        </Button>
        <Grid item xs={2} textAlign={'end'} >
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => { listEscalas() }}
          >
            <ReplayTwoToneIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid container
        py={2}
        px={1}
        gap={1}
        justifyContent={{
          xs: 'center', sm: 'start'
          // , md: 'center', lg: 'center' 
        }}
        bgcolor={grey[300]}
        height={'76vh'}
        overflow={'auto'}
      >
        {filteredEscalas.map((escala) => (
          <Grid item key={escala.idescala} >
            {escala.isactive && (
              <Card key={escala.idescala}
                sx={{ backgroundColor: getEscalaCor(escala.status, escala.diamesano), }}>
                <CardContent >
                  <Grid container
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'} >
                    <Typography variant="h4" component="div"
                      sx={{
                        border: '1px',
                        borderRadius: '0px',
                      }}
                    >
                      {`${getDiaSemana(escala.diamesano)}`}
                    </Typography>
                    <IconButton
                      onClick={() => { handleEditar(escala) }}
                      disabled={disabled || (escala.status !== escalaStatus.Prevista)}
                      size='medium'
                    >
                      <EditCalendarTwoToneIcon fontSize='medium' />
                    </IconButton>

                  </Grid>
                  <Typography variant="h5" component="div" sx={{ marginTop: '5px' }}>
                    {`Dia: ${new Date(escala.diamesano).toLocaleDateString()} `}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {/* {`Situação: ${escala.status === escalaStatus.Executando ? escala.diamesano !== _dia ? 'Aguardando execução...' : getEscalaStatus(escala.status) : getEscalaStatus(escala.status)}`} */}
                    {`Situação: ${getEscalaSituacao(escala.status, escala.diamesano)}`}
                  </Typography>
                  <Typography variant="h5">
                    {`Distribuição: ${getDistribuicao(escala.tpdistribuicao)}`}
                  </Typography>
                  <Typography variant="h5">
                    {`Horário: ${dayjs(escala.hrinicial).format('HH:mm')} / ${dayjs(escala.hrfinal).format('HH:mm')}`}
                  </Typography>
                </CardContent>
                <CardActions sx={{ marginTop: '-15px' }}>
                  <Grid container direction={'row'} justifyContent={'space-between'} >
                    <Grid container sx={{ width: '100%', justifyContent: 'flex-end' }} marginBottom={'10px'}  >
                      <Button id='btCorretores'
                        variant='contained'
                        disabled={disabled}
                        size="small"
                        onClick={() => handleCorretores(escala)}
                      >Corretores</Button>
                    </Grid>
                    <Grid container sx={{ width: '100%', justifyContent: 'space-between' }} gap={1} >
                      <Button id='btExcluir'
                        variant='contained'
                        disabled={disabled || escala.status !== escalaStatus.Prevista}
                        onClick={() => { handleExcluir(escala) }}
                        size="small" >Excluir</Button>
                      <Button id='btSuspender'
                        variant='contained'
                        disabled={disabled || escala.status !== escalaStatus.Executando || escala.diamesano < hoje}
                        onClick={() => { handleSuspender(escala) }}
                        size="small" >Suspender</Button>
                      <Button id='btExecutar'
                        variant='contained'
                        disabled={disabled || (escala.status !== escalaStatus.Ordenada && escala.status !== escalaStatus.Suspensa)}
                        onClick={() => { handleExecutar(escala) }}
                        size="small" >Executar</Button>

                    </Grid>
                  </Grid>
                </CardActions>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: '5px', marginTop: '-10px' }}>
                  <Typography variant="body2" color="text.secondary" fontSize={'10px'} sx={{ marginTop: '10px' }}>
                    {`${new Date(escala.createdat).toLocaleDateString()} ${new Date(escala.createdat).toLocaleTimeString()}`}
                  </Typography>
                </Box>
              </Card>
            )}
          </Grid>
        ))}
      </Grid>
      {openDialogAdd && (<AdicionarEscala
        setOpenDialogAdd={setOpenDialogAdd}
        openDialogAdd={openDialogAdd}
        idconta={idconta}
        idusuario={idusuario}
        setSnackbar={setSnackbar}
        listEscalas={listEscalas}

      />)}
      {openDialogExcluir && (<ExcluirEscala
        setOpenDialogExcluir={setOpenDialogExcluir}
        openDialogExcluir={openDialogExcluir}
        escala={escala} escalas={escalas} filteredEscalas={filteredEscalas}
        idusuario={idusuario}
        setSnackMessage={setSnackMessage}
        setSnack={setSnack}
        setSnackbar={setSnackbar}
        listEscalas={listEscalas}
      />)}
      {openCorretores && (<HabilitarCorretores
        openCorretores={openCorretores}
        setOpenCorretores={setOpenCorretores}
        escala={escala}
        listEscalas={listEscalas}
        setSnackbar={setSnackbar}
        idusuario={idusuario}
      />)}
      {openDialogEditar && (<EditarEscala
        setOpenDialogEditar={setOpenDialogEditar}
        openDialogEditar={openDialogEditar}
        idconta={idconta}
        idusuario={idusuario}
        setSnackbar={setSnackbar}
        listEscalas={listEscalas}
        escala={escala}
      />)}
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
          <Alert {...snackbar} onClose={handleCloseSnackbar}>{snackbar.message}</Alert>
        </Snackbar>
      )}
    </Grid>
    // </Container >
  )
}

