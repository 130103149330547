import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { getDistribuicao } from '../../../utils/crm/getDistribuicao';
// import { listCorretores, updateEscala } from '../../../controllers/crm/escalaController';
import { getDiaSemana } from '../../../utils/crm/getDiaSemana';
import { getListOperadorEscala, getOperEscala, setOperEscala } from '../../../controllers/crm/operescalaController';
import { grey } from '@mui/material/colors';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import { operadorStatus } from '../../../constants/crm';


export function ListarParticipantes(
  {
    openParticipantes,
    setOpenParticipantes,
    escala,
    setSnackbar
  }) {

  const [load, setLoad] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [operadores, setOperadores] = useState([])
  const [filterOperadores, setFilterOperadores] = useState([])
  const [todos, setTodos] = useState(false)

  const handleNo = () => {
    setOpenParticipantes(false);
  };

  const handleCheck = (e) => {
    if (e.target.checked) {
      setFilterOperadores(operadores.filter((corretor) => corretor.status === operadorStatus.Disponivel))
    } else {
      setFilterOperadores(operadores)
    }
    setTodos(e.target.checked)
  }

  async function loadOperadorescala() {
    setLoad(true)
    const dados = {
      idescala: escala.idescala,
    }
    const api = await getListOperadorEscala(dados)
    if (api.status !== 200) {
      setSnackbar({ severity: 'error', message: 'Falha ao carregar dados do operador!' });
      setLoad(false)
      return
    }
    else if (api.data) {
      setOperadores(api.data)
      // filtrar os operadores que possuem ordem
      setFilterOperadores(api.data)
      setLoad(false)
    }
  }
  useEffect(() => {
    if (openParticipantes) {
      loadOperadorescala(false)
    }
  }, [])

  return (
    <Dialog
      // maxWidth="xs"
      open={openParticipantes}
      onClose={handleNo}
    >
      {(load) && ((
        <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
          <CircularProgress />
        </div>
      ))}
      <DialogTitle>
        <Typography variant="h4" component="div" sx={{ display: 'flex', justifyContent: 'center' }}>
          {`${getDiaSemana(escala.diamesano).toLocaleUpperCase()} - ${new Date(escala.diamesano).toLocaleDateString()}`}
        </Typography>
        <Typography variant="h5" component="div" sx={{ display: 'flex', justifyContent: 'center' }}>
          {`Distribuição: ${getDistribuicao(escala.tpdistribuicao)} - ${dayjs(escala.hrinicial).format('HH:mm')} / ${dayjs(escala.hrfinal).format('HH:mm')}`}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container
          direction={'row'}
          minHeight={'120px'}
        // maxWidth={'370px'}
        >
          <Grid container
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant="h3" component="div" >
              Corretores Disponíveis
            </Typography>
            <Button
              variant="contained"
              disabled={load}
              onClick={() => { loadOperadorescala() }}
              size="small"
            >
              <ReplayTwoToneIcon />
            </Button>
          </Grid>
          <Grid container direction={'row'}
            justifyContent={'flex-start'}
            border={1}
            mt={1}
          >
            {filterOperadores.length > 0 && (
              <Grid container direction={'row'} gap={1}
                overflow={'auto'}
                height={'300px'}
                px={todos ? 0 : 1}
                py={1}
                alignContent={'flex-start'}
              >
                {filterOperadores.map((corretor, i) => (
                  <Grid container direction={'row'}
                    gap={1}
                    key={i}
                    sx={{
                      backgroundColor: i % 2 === 0 ? grey[200] : grey[300], // Alternar cores
                    }}
                  >
                    {todos && (
                      <Grid item >
                        <Typography variant="body1" fontSize={12}>
                          {corretor.ordem} -
                        </Typography>
                      </Grid>
                    )}
                    <Grid item >
                      <Typography variant="body1" fontSize={12}>
                        {corretor.nome.length > 15 ? corretor.nome.substring(0, 15) + '...' : corretor.nome}
                      </Typography>
                    </Grid>
                    <Grid item px={1}>
                      <Typography variant="body1" fontSize={12} >
                        {dayjs(corretor.hio).format('HH:mm')} / {dayjs(corretor.hfo).format('HH:mm')}
                      </Typography>
                    </Grid>
                    {todos && (
                      <Grid item px={1}>
                        <Typography variant="body1" fontSize={12} >
                          Leads: {corretor.qtd}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                ))
                }
              </Grid>
            )}
            {filterOperadores.length === 0 && (
              <Typography variant="body1" fontSize={12} p={1} height={'300px'}>
                Não há corretores cadastrados ou o Online não está em execução.
              </Typography>
            )}
          </Grid>
          <Grid item
            display={'flex'} 
            justifyContent={'flex-start'}
            alignItems={'center'}>
            <Checkbox
              checked={todos}
              onChange={handleCheck}
            />
            <Typography variant="body1" fontSize={14} component="div" >
              Corretores Selecionados
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'flex-end'} gap={2} pr={1}>
          <Button variant="contained"
            onClick={() => handleNo()}
            disabled={disabled} size='small'>
            Fechar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );

}
