import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, CardActions, Typography, Grid, Container, Box, CircularProgress } from '@mui/material';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import { getEscala } from '../../../controllers/crm/escalaController';
import { getDiaSemana } from '../../../utils/crm/getDiaSemana';
import { getDistribuicao } from '../../../utils/crm/getDistribuicao';
import { CorretorIncluir } from './CorretorIncluir';

import { getEscalaSituacao } from '../../../utils/crm/getEscalaSituacao';
import { getEscalaCor } from '../../../utils/crm/getCorEscala';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br.js';
import { ListarParticipantes } from './ListarParticipantes';
dayjs.locale('pt-br');


export default function ParticiparEscala({
  account,
  setShowMenu,
  setShowParticiparEscala,
  showParticiparEscala,
  setUpdate,
  update,
  setSnackbar,
  snackbar,
}) {

  // Constantes Recebidas
  // const [account, setAccount] = useState(props.account)

  const idconta = account.conta
  const idusuario = account.id
  const nome = account.nome
  // console.log('nome', nome)


  // Constantes de Estado
  const [operadores, setOperadores] = useState([])
  const [escalas, setEscalas] = useState([])
  const [filteredEscalas, setFilteredEscalas] = useState([])
  const [escala, setEscala] = useState({})
  const [open, setOpen] = useState(false)
  const [snack, setSnack] = useState(false)
  const [snackMessage, setSnackMessage] = useState('')
  const [loading, setLoading] = useState(true)
  const [disabled, setDisabled] = useState(false)
  const [newDay, setNewDay] = useState(dayjs(new Date()));
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openCorretores, setOpenCorretores] = useState(false);
  const [openDialogExcluir, setOpenDialogExcluir] = useState(false);
  const [openDialogEditar, setOpenDialogEditar] = useState(false);
  const [openCorretorEscala, setOpenCorretorEscala] = useState(false);
  const [openCorretorIncluir, setOpenCorretorIncluir] = useState(false);
  const [openParticipantes, setOpenParticipantes] = useState(false);

  const [hrInicial, setHrInicial] = useState(dayjs().hour(9).minute(0))
  const [hrFinal, setHrFinal] = useState(dayjs().hour(18).minute(0))


  const hoje = dayjs(dayjs().format('YYYY-MM-DD')).toISOString()


  // Constantes do Snackbar
  // const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  // Funções das Escalas

  // Listar Escalas
  async function listEscalas() {
    try {
      setLoading(true)
      const query = {
        take: 8,
        where: {
          idconta: idconta,
          isactive: true,
          diamesano: { gte: newDay.add(-1, 'day').toDate() }
        },
        orderBy: [
          { diamesano: 'asc' }
        ]
      };
      const response = await getEscala(query);
      // console.log(response)
      if (response.status !== 200) {
        setSnackbar({ severity: 'error', message: 'Erro ao carregar controles!' });
        setLoading(false)
        return;
      }
      if (response.data) {
        if (response.data.length === 0) {
          setEscalas([]);
          setFilteredEscalas([]);
          setSnackbar({ severity: 'info', message: 'Nenhum controle cadastrado!' });
        }
        if (response.data.length > 0) {
          setEscalas(response.data);
          setFilteredEscalas(response.data);
        }
      }
      setLoading(false)
    }
    catch (error) {
      setLoading(false)
      setSnackbar({ severity: 'error', message: 'Serviço fora do ar, tente novamente mais tarde.' });
    }
  }

  // Abrir Participantes
  function handleParticipantes(_escala) {
    setEscala(_escala)
    setOpenParticipantes(true)
  }

  // Abrir Corretores
  function handleCorretores(_escala) {
    setEscala(_escala)
    // setOpenCorretorEscala(true)
    setOpenCorretorIncluir(true)

  }

  useEffect(() => {
    listEscalas()
  }, [newDay])

  return (
    <Container>
      <Grid container
        height={'85vh'}
        overflow={'auto'}
        direction="row"
      >
        {(loading) && ((
          <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
            <CircularProgress />
          </div>
        ))}
        {openCorretorIncluir && (<CorretorIncluir
          openCorretorIncluir={openCorretorIncluir}
          setOpenCorretorIncluir={setOpenCorretorIncluir}
          escala={escala}
          listEscalas={listEscalas}
          setSnackbar={setSnackbar}
          idconta={idconta}
          idusuario={idusuario}
          hrInicial={hrInicial}
          setHrInicial={setHrInicial}
          hrFinal={hrFinal}
          setHrFinal={setHrFinal}
          nome={nome}
        />)}
        {openParticipantes && <ListarParticipantes
          openParticipantes={openParticipantes}
          setOpenParticipantes={setOpenParticipantes}
          escala={escala}
          setSnackbar={setSnackbar}
        />
        }

        <Grid container
          py={1}
          gap={{ xs: .3, sm: 1 }}
          alignContent={'start'}
          justifyContent={{ xs: 'center', sm: 'start' }}
          bgcolor={'#f5f5f5'}
          p={1}

        >
          <Grid container
            direction={'row'}
            justifyContent={'space-between'}
            alignContent={'center'}
            p={1}
            bgcolor={'white'}
            height={'50px'}
            overflow={'auto'}
          >
            <Typography variant="h3" alignContent={'center'} >
              LEADS ONLINE - PARTICIPAR
            </Typography>
            <Button
              variant="contained"
              disabled={loading}
              onClick={() => { listEscalas() }}
            >
              <ReplayTwoToneIcon />
            </Button>
          </Grid>

          {filteredEscalas.map((escala) => (
            <Grid item key={escala.idescala}
              my={1}
            >
              {escala.isactive && (
                <Card key={escala.idescala}
                  sx={{ backgroundColor: getEscalaCor(escala.status, escala.diamesano), }}>
                  <CardContent >
                    <Grid container
                      direction={'row'}
                      // justifyContent={'space-between'}
                      width={{ xs: '260px', sm: '200px' }}
                    >
                      <Typography variant="h4" component="div">
                        {`${getDiaSemana(escala.diamesano)}`}
                      </Typography>
                    </Grid>
                    <Typography variant="h5" component="div" sx={{ marginTop: '5px' }}>
                      {`Dia: ${new Date(escala.diamesano).toLocaleDateString()} `}
                    </Typography>
                    <Typography variant="h5" component="div">
                      {/* {`Situação: ${escala.status === escalaStatus.Executando ? escala.diamesano !== _dia ? 'Aguardando execução...' : getEscalaStatus(escala.status) : getEscalaStatus(escala.status)}`} */}
                      {`Situação: ${getEscalaSituacao(escala.status, escala.diamesano)}`}
                    </Typography>
                    <Typography variant="h5">
                      {`Distribuição: ${getDistribuicao(escala.tpdistribuicao)}`}
                    </Typography>
                    <Typography variant="h5">
                      {`Horário: ${dayjs(escala.hrinicial).format('HH:mm')} / ${dayjs(escala.hrfinal).format('HH:mm')}`}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ marginTop: '-15px' }}>
                    <Grid container direction={'row'} justifyContent={'space-between'} >
                      <Grid container sx={{ width: '100%', justifyContent: 'space-between' }} marginBottom={'10px'} gap={1} >
                        <Button id='btCorretores'
                          variant='contained'
                          disabled={disabled}
                          size="small"
                          onClick={() => handleParticipantes(escala)}
                        >Participantes</Button>
                        {/* </Grid>
                      <Grid container sx={{ width: '100%', justifyContent: 'flex-end' }} marginBottom={'10px'}  > */}
                        <Button id='btCorretores'
                          variant='contained'
                          disabled={disabled}
                          size="small"
                          onClick={() => handleCorretores(escala)}
                        >Participar</Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: '5px', marginTop: '-10px' }}>
                    <Typography variant="body2" color="text.secondary" fontSize={'10px'} sx={{ marginTop: '10px' }}>
                      {`${new Date(escala.createdat).toLocaleDateString()} ${new Date(escala.createdat).toLocaleTimeString()}`}
                    </Typography>
                  </Box>
                </Card>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  )
}

