import React, { useEffect, useState } from 'react';
import { Typography, TextField, Button, Box, Grid, Checkbox, Snackbar, Alert, CircularProgress, } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import { makeStyles } from '@mui/styles';
import { green, yellow, } from '@mui/material/colors';
import { TextFieldMask } from '../../utils/TextfieldMask/TextfieldMask';
import { Copyright } from '../Copyright';
import { Logotipo } from '../../components/Logotipo/logotipo';
import { TermosPoliticas } from '../termos/termospoliticas';
import { useParams } from 'react-router-dom';
import { leadProcessoStatus } from '../../constants/crm';
import { createLead } from '../../controllers/crm/leadController';
import { landingPageView } from '../../utils/metaPixelEvents';


const theme = createTheme();

// const useStyles = makeStyles(() => ({
//   root: {
//     justifyItems: 'center',
//     marginTop: theme.spacing(2),
//     // textAlign: 'center',
//     [theme.breakpoints.down('sm')]: {
//       marginTop: theme.spacing(4),
//     },
//     color: 'white',
//   },
//   // form: {
//   //   marginTop: theme.spacing(2),
//   //   [theme.breakpoints.down('sm')]: {
//   //     marginTop: theme.spacing(2),
//   //   },
//   // },
//   // submitButton: {
//   //   marginTop: theme.spacing(2),
//   //   [theme.breakpoints.down('sm')]: {
//   //     marginTop: theme.spacing(1),
//   //   },
//   // },
//   // carousel: {
//   //   width: '500px',
//   //   marginTop: theme.spacing(4),
//   //   '& img': {
//   //     width: '100%',
//   //     height: 'auto',
//   //   },
//   //   [theme.breakpoints.down('sm')]: {
//   //     width: '90%',
//   //     margin: theme.spacing(0, 2),
//   //     '& img': {
//   //       width: '100%',
//   //       height: 'auto',
//   //     },
//   //   },

//   // },
// }));

const LandingPage = () => {

  // const classes = useStyles();

  let params = useParams();
  let processo = leadProcessoStatus.Automatico

  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [interesse, setInteresse] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [wasSend, setWasSend] = useState(false);
  const [hasWhatsapp, setHasWhatsapp] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleClose(event, reason) {
    setHasError(false);
  }

  function validarForm() {
    if (nome === '' || telefone === '') {
      setMsgError('Preencha os campos Nome e Telefone!');
      setHasError(true);
      return false;
    }
    return true;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (!validarForm()) {
      return;
    }
    let data = {
      id1: params.id1,
      id2: params.id2,
      id3: params.id3,
      id4: params.id4,
      nome: nome,
      telefone: telefone,
      email: email,
      interesse: interesse,
      processo: processo,
      whatsapp: hasWhatsapp
    };
    setLoading(true);
    const result = await createLead(data);
    if (result.status === 200) {
      setWasSend(true);
      setDisabled(true);
      setLoading(false);
    } else if (result.status === 223) {
      setLoading(false);
      setMsgError('Erro de chaves inválidas!');
      setHasError(true);
      setDisabled(false);
    } else if (result.status === 250) {
      setLoading(false);
      setWasSend(true);
      setDisabled(true);
    }
    else {
      setLoading(false);
      setMsgError('Erro ao enviar os dados!');
      setHasError(true);
    }
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    landingPageView();
  }, []);

  return (
    <ThemeProvider
      theme={theme}
    >
      <Grid
        container
        justifyContent={'center'}
        direction={'column'}
        alignItems={'center'}
      >
        {(loading) && ((
          <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
            <CircularProgress />
          </div>
        ))}
        <Grid
          container
          bgcolor={green[100]}
          p={2}
          direction={'column'}
          width={{ xs: '95%', md: '600px' }}
          height={'90%'}
          mt={2}
        >
          <Grid item>
            <Typography
              variant="h5"
              component="h5"
              gutterBottom
              textAlign={'center'}
            >
              Bem-vindo à sua nova casa!
            </Typography>
          </Grid>
          <Grid item
            sx={{
              width: '500px',
              margin: theme.spacing(4),
              '& img': {
                width: '100%',
                height: 'auto',
              },
              [theme.breakpoints.down('sm')]: {
                width: '90%',
                margin: theme.spacing(0, 2),
                '& img': {
                  width: '100%',
                  height: 'auto',
                },
              },
            }}
          >
            <Slider {...settings}>
              <div>
                <img src="https://melnickpoa.com.br/wp-content/uploads/2024/04/Open-Major-Guarita.webp" alt="Guarita" />
              </div>
              <div>
                <img src="https://melnickpoa.com.br/wp-content/uploads/2024/04/Open-Major-Piscina.webp" alt="Piscina" />
              </div>
              <div>
                <img src="https://melnickpoa.com.br/wp-content/uploads/2024/04/Open-Major-Quadra-Poliesportiva.webp" alt="Quadra" />
              </div>
              <div>
                <img src="https://melnickpoa.com.br/wp-content/uploads/2024/04/Open-Major-Petplace.webp" alt="Pet Place" />
              </div>
              <div>
                <img src="https://melnickpoa.com.br/wp-content/uploads/2024/04/Open-Major-Playground.webp" alt="Playground" />
              </div>
              <div>
                <img src="https://melnickpoa.com.br/wp-content/uploads/2024/04/Open-Major-Quisoque.webp" alt="Quiosque" />
              </div>
              <div>
                <img src="https://melnickpoa.com.br/wp-content/uploads/2024/04/Open-Major-Lazer.webp" alt="Área de Lazer" />
              </div>
              <div>
                <img src="https://melnickpoa.com.br/wp-content/webp-express/webp-images/uploads/2024/05/Open-Alto-Ipiranga-Plantas-1.jpg.webp" alt="Plantas" />
              </div>
            </Slider>
          </Grid>
          <Grid container
            sx={{ height: 'auto', width: 'auto' }}
            justifyContent={'center'}
            mt={{ xs: 4, md: 2 }}
            direction={'column'}
          >
            <Typography variant="body1" component="p" gutterBottom>
              Para mais informações sobre esse imóvel, pedimos que preencha os campos abaixo:
            </Typography>
            <Grid item
              bgcolor={'#f5f5f5'}
              px={1}
              component={'form'}
              noValidate
              autoComplete='false'
              display={'flex'}
              flexDirection={'column'}
              justifySelf={'center'}
              pb={2}
            >
              <TextField
                id="nome"
                label="Nome"
                variant='standard'
                fullWidth
                onChange={(event) => setNome(event.target.value)}
                disabled={disabled}
              />
              <Box component={'div'} marginTop={'10px'} alignContent='center'>
                <TextFieldMask
                  sx={{ textAlign: 'left' }}
                  placeholder={'(99) 99999-9999'}
                  variant='standard'
                  size='small'
                  id="telefone"
                  name='telefone'
                  label="Telefone"
                  value={telefone}
                  onChange={(event) => setTelefone(event.target.value)}
                  mask={'phone'}
                  disabled={disabled}
                />

                <Checkbox
                  id='whatsapp'
                  required
                  checked={hasWhatsapp}
                  onChange={() => setHasWhatsapp(!hasWhatsapp)}
                  disabled={disabled}
                />Whatsapp
              </Box>
              <TextFieldMask
                sx={{ marginTop: 1 }}
                fullWidth
                // placeholder={'(99) 99999-9999'}
                variant='standard'
                size='small'
                id="email"
                name='email'
                autoComplete='false'
                label="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                // helperText="Email para contato"
                mask={'email'}
                disabled={disabled}
              />

              <TextField
                style={{ marginTop: 10, marginBottom: 10 }}
                placeholder={'ex: apartamento em bairro X na cidade Y, com 2 quartos e garagem, com parcelas de até R$ $$$ e entrada de aproximadamente R$ $$$$'}
                id="interesse"
                label="Características de Interesse"
                variant='standard'
                fullWidth
                autoComplete='false'
                multiline
                rows={3}
                onChange={(event) => setInteresse(event.target.value)}
                disabled={disabled}
              />
              <Button
                id='btnEnviar'
                type='submit'
                variant='contained'
                color='primary'
                fullWidth
                style={{ marginTop: 10 }}
                onClick={handleSubmit}
                disabled={disabled}
              >
                <span style={{ fontSize: '12px' }}> Enviar Dados para o Corretor de Imóveis</span>
              </Button>
            </Grid>

            {wasSend && (
              <div style={{ textAlign: 'center', backgroundColor: yellow[100], padding: 1, marginTop: '20px' }}>
                <p><b>Agradecemos por escolher nossa equipe!</b></p>
                <p><b>Em breve, um de nossos corretores entrará em contato com você para darmos início à busca pelo imóvel perfeito. 🏠✨</b></p>
              </div>
            )}
            {!wasSend && (
              <Typography mt={1} px={0} variant="body1"
                component="p" gutterBottom>
                Assim que recebermos essas informações, um de nossos Corretores de Imóveis entrará em contato com você. Obrigado!
              </Typography>
            )}
          </Grid>
        </Grid>
        <Box component={'div'}
          mb={1}
          textAlign={'center'} >

          <Logotipo
            height={'30px'}
            width={'auto'}
            mt={'10px'}
          />
          <TermosPoliticas fontSize={'8px'} />
          <Copyright color="White" />
        </Box>
        <Snackbar
          open={hasError}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
          >
            {msgError}
          </Alert>
        </Snackbar>
      </Grid>
    </ThemeProvider >
  );
};

export default LandingPage;